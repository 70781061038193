:root {
	--mainOpacity: rgba(0, 0, 0, 0.486);
	--blackOpacity: rgba(0, 0, 0, 0.849);
	--whiteLabel: rgb(255, 255, 255);
	--silverLabel: rgb(199, 199, 199);
	--matteBlack: rgb(39, 39, 39);
	--lightGrey: rgb(65, 65, 65);
	--blackLabel: rgb(10, 10, 10);
	--darkGrey: rgb(27, 27, 27);

}

@font-face {
	font-family: 'AMCAP Eternal';
	src: url('../src/AMCAP Eternal.otf') format('truetype');
}

/* Establishes the text and body style of the document */
body {
	margin: 0;
	scroll-behavior: smooth;
	overflow-x: hidden;
	font-family: "AMCAP Eternal";
	align-items: center;
	justify-content: center;
}

.container {
	position: relative;
}

/* Keyframe Animations */

@keyframes smoothScroll {
	0% {
		transform: translateY(0);
	}

	100% {
		transform: translateY(-30px);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}


/* Navigation menu styles */
nav {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	background-color: var(--blackLabel);
	box-shadow: 2px 2px 2px 2px var(--mainOpacity);
	color: var(--whiteLabel);
	padding: 1%;
	padding-left: 1%;
	padding-bottom: 0.5%;
	position: fixed;
	font-size: 1.05em;
	top: 0;
	left: 0;
	width: 100%;
	margin-top: -4px;
	z-index: 1;
	order: 2;
}


/* Navigation menu */

.menu {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	opacity: 0s;

}

.menu li {
	margin: 0 30px;
	margin-left: 20px;
}

.menu a {
	color: var(--silver);
	text-decoration: none;
	transition: all 0.2s;
}

.menu a:hover {
	color: var(--matteBlack);
	text-decoration: none;
	padding: 20px;
}

.menu.open {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: var(--darkGrey);
	box-shadow: 5px 5px 5px var(--mainOpacity);
	padding: 30%;
	position: absolute;
	top: 100%;
	left: 0;

}

.menu.open li {
	opacity: 1;
	margin: 5%;
	border-radius: 10px;
	transform: translateY(0);
	transition: opacity 0.3s, transform 0s linear 0s;
}

.menu.open li:nth-child(1) {
	transition-delay: 0.35s;
}

.menu.open li:nth-child(2) {
	transition-delay: 0.45s;
}

.menu.open li:nth-child(3) {
	transition-delay: 0.55s;
}

.menu.open li:nth-child(4) {
	transition-delay: 0.65s;
}

/* Overlay styles */
.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Adjust the opacity as desired */
	z-index: 1;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease;
	backdrop-filter: blur(10px);
	/* The blur effect applied */
}

.overlay.open {
	opacity: 1;
	visibility: visible;
}


/* Hamburger Menu - Main */
.hamburger {
	display: none;
	border: none;
	flex-direction: column;
	cursor: pointer;
	margin-left: auto;
	margin-top: -6px;
	
	/* Adjust the left margin as needed */
}

.hamburger span {
	background-color: var(--whiteLabel);
	box-shadow: 1px 1px 1px var(--mainOpacity);
	width: 30px;
	height: 3px;
	margin: 3.25px 0;
	transition: all 0.3s ease;
}


.hamburger.active span:nth-child(1) {
	transform: rotate(38deg) translate(5px, 5px);
	transform-origin: top left;
}

.hamburger.active span:nth-child(2) {
	opacity: 0;
}

.hamburger.active span:nth-child(3) {
	transform: rotate(-38deg) translate(5px, -5px);
	transform-origin: bottom left;
}

/* Parallax container styles */
.parallax-container {
	height: 100vh;
	overflow: hidden;
	margin: 2%;
}

/* Parallax background image styles */
.home {
	background-image: url("./img/CEARCO_Black_Background.jpg");
	background-size: cover;
	background-position: 0%, 50%;
	background-repeat: no-repeat;
	position: fixed;
	height: 100% !important;
	width: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
	z-index: -1;

}

/* Back to Top Button */

#back-to-top-btn {
	display: none;
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: var(--mainOpacity);
	color: var(--offWhite);
	padding: 1px 5px;
	border-radius: 5px;
	cursor: pointer;
	font-size: 30px;
	opacity: 0.7;
	transition: opacity 0.3s;
	animation: fadeIn 2s forwards;

}

#back-to-top-btn:hover {
	opacity: 1;

}

/* Content section styles */
.content {
	position: absolute;
	font-size: 1.8em;
	padding: 1px;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	text-shadow: 5px 5px 5px var(--mainOpacity);
	color: var(--whiteLabel);
	max-width: 90%;
	/* Keeps the width of the paragraph or sentences stable when on desktop */
}

.content h1 {
	font-size: 2.05em;

}

.img-prod {
	border-radius: 30px;
}

strong {
	font-size: 1.5em;
}

/* Button styles */
.learn-more-01 {
	background-color: var(--whiteLabel);
	color: var(--matteBlack);
	text-decoration: none;
	border: none;
	padding: 15px 15px;
	font-size: 0.7em;
	font-family: "AMCAP Eternal";
	border-radius: 5px;
	box-shadow: 3px 3px 3px var(--blackOpacity);
	margin-top: 7%;
	cursor: pointer;
	transition: background-color 0.5s;
	animation: smoothScroll 1.5s ease-in-out forwards;
}

.learn-more-01:hover {
	color: var(--whiteLabel);
	background-color: var(--matteBlack);
}

#about {
	background-image: linear-gradient(60deg,
			var(--matteBlack),
			var(--blackOpacity)),
		url(../src/img/ourhistory_bg_01.jpg);
	background-size: cover;
	background-position: 0%, 50%;
	background-attachment: fixed;
	text-align: center;
	height: auto !important;
	width: 100%;
	background-repeat: no-repeat;
	padding-top: 10%;
	padding-bottom: 10%;
	z-index: -1;

}

.about-title {
	font-size: 2.25em;
	text-align: center;
	color: var(--whiteLabel);
	text-shadow: 3px 3px 3px var(--mainOpacity);
}

.about-paragraph {
	font-size: 1.75em;
	color: var(--whiteLabel);
	margin: auto;
	max-width: 90%;
	/* Keeps the width of the paragraph or sentences stable when on desktop */
}

/* Product Table */
#product {
	background-image: url("./img/CEARCO_Black_Background.jpg");
	background-size: cover;
	background-position: 0%, 50%;
	background-attachment: fixed;
	height: auto !important;
	width: 100%;
	background-repeat: no-repeat;
	opacity: var(--mainOpacity);
	padding-top: 2%;
	padding-bottom: 2%;
}

.product-paragraph {
	text-align: center;
	text-shadow: 12px 12px 12px var(--mainOpacity);
	color: var(--whiteLabel);
	font-size: 2.5em;
}

.card-table {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	grid-gap: 80px;
	justify-items: center;
	transform: scale(1);
	padding: 5%;

}


.card {
	display: flex;
	width: 450px;
	padding: 1.5%;
	overflow: hidden;
	box-shadow: 30px 30px 30px var(--mainOpacity);
	transition: margin 0.3s ease-in-out;
	margin-bottom: 10px;

}

.card:hover {
	margin-top: -10px;
}

.card-image img {
	width: 100%;
	height: auto;
}

.card-title {
	font-family: "AMCAP Eternal";
}

.card-content {
	padding: 16px;
}

h2 {
	margin-top: 0;
}

.read-more-button {
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;

}

.read-more-button button {
	background-color: var(--darkGrey);
	color: var(--whiteLabel);
	border: none;
	padding: 8px 16px;
	border-radius: 4px;
	cursor: pointer;
	font-family: "AMCAP Eternal";
	font-size: 16px;

}

.read-more-button button:hover {
	background-color: var(--silverLabel);
	color: var(--lightGrey);
}


/* Footer */

.site-footer {

	background-color: var(--blackLabel);
	padding: 2% 0;
	font-size: 18px;
	line-height: 24px;
	color: var(--silverLabel);
}

.site-footer hr {
	border-top-color: var(--lightGrey);
	opacity: 0.5;
}

.site-footer hr.small {
	margin: 20px 0;
}

.site-footer h6 {
	color: var(--whiteLabel);
	font-size: 16px;
	text-transform: uppercase;
	margin-top: 5px;
	letter-spacing: 2px;
}

.site-footer a {
	color: var(--silverLabel);
	transition: all 0.5s;
}

.site-footer a:hover {
	color: var(--whiteLabel);
	text-decoration: none;
}

.footer-links {
	padding-left: 0;
	list-style: none;
}

.footer-links li {
	display: block;
}

.footer-links a {
	color: var(--silverLabel);
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
	color: var(--whiteLabel);
	text-decoration: none;
}

.footer-links.inline li {
	display: inline-block;
}

.site-footer .social-icons {
	text-align: right;
}

.site-footer .social-icons a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	margin-left: 10px;
	margin-right: 0;
	border-radius: 100%;
	background-color: var(--lightGrey);
}

.text-justify {
	text-align: justify;
	margin-right: 12%;
}

.copyright-text {
	margin: 0;
}

/* Scrollbar Webkit*/

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background-color: var(--mainOpacity);
}

::-webkit-scrollbar-thumb {
	background-color: var(--matteBlack);
}

::-webkit-scrollbar-thumb:hover {
	background-color: var(--darkGrey);
}

/* Media queries for desktop */
@media (max-width: 1280px) {
	body {
		overflow-x: hidden;
	}

}

/* Media queries for mobile */

@media (max-width: 989px) {

	body {
		overflow-x: hidden;
	}

	main {
		padding-top: 5vh;
		padding-bottom: 20vh;
	}

	/* Media - Navigation menu */
	nav {
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		z-index: 1;
		padding: 2%;
		padding-left: 2%;
		

	}

	.home {
		height: auto;
	}

	.menu {
		width: 100%;
		padding-top: 40%;
		display: none;
		animation: fade-in 0.5s ease-in-out forwards;
		/* Hide menu by default */
	}

	.menu.open {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		background-color: var(--darkGrey);
		padding: 20%;
		position: absolute;
		top: 100%;
		left: 0;
		opacity: 1;
		visibility: visible;
		transition: opacity 0.3s, visibility 0s linear 0s;
	}

	.menu li {
		margin: 10px 0;
	}

	.menu li:hover {
		background-color: var(--lightGrey);
		width: 100%;
		padding: 3%;
		animation: fade-in 0.5s ease-in-out;
	}

	/*  Hamburger Menu - Media  */
	.hamburger {
		display: flex;
		padding: 1%;
		margin-top: 6px;
	}

	.hamburger span {
		background-color: var(--whiteLabel);
		transition: all 0.3s ease;
	}

	.hamburger.active span:nth-child(1) {
		transform: rotate(38deg);
		transform-origin: top left;
	}

	.hamburger.active span:nth-child(2) {
		opacity: 0;
	}

	.hamburger.active span:nth-child(3) {
		transform: rotate(-38deg);
		transform-origin: bottom left;
	}

	/*  Parallax container  */

	.parallax-container {
		height: auto;
	}

	/*  Media content  */
	.content {
		position: static;
		margin: auto;
		transform: none;
		font-size: 1.5em;
		padding-top: 30%;
	}

	.content h1 {
		font-size: 1.8em;
	}

	/**** Image content *****/
	.img-prod {
		width: 100%;
		height: auto;
	}

	.about-paragraph {
		margin: 30px;
	}

	/*  Product content  */
	#product {
		padding-top: 5%;
		padding-bottom: 5%;
	}

	.card-table {
		grid-template-columns: 1fr;
		transform: scale(1);

	}

	.card {
		width: 100%;
		/* Make the cards take the full width of the container */
		box-shadow: 5px 5px 5px var(--mainOpacity);
		margin: 5%;
	}
}

/* Footer */

.site-footer {
	padding-bottom: 0;
}

.site-footer .copyright-text {
	text-align: center;
}

.site-footer .social-icons {
	margin-top: 2%;
	text-align: left;
}

.social-icons {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}

.social-icons li {
	display: inline-block;
	margin-bottom: 4px;
}

.social-icons li.title {
	margin-right: 15px;
	text-transform: uppercase;
	color: #96a2b2;
	font-weight: 700;
	font-size: 13px;
}

.social-icons a {
	background-color: #eceeef;
	color: #818a91;
	font-size: 16px;
	display: inline-block;
	line-height: 44px;
	width: 44px;
	height: 44px;
	text-align: center;
	margin-right: 8px;
	border-radius: 100%;
	-webkit-transition: all 0.2s linear;
	-o-transition: all 0.2s linear;
	transition: all 0.2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
	color: var(--whiteLabel);
	background-color: var(--silverLabel);
}

.social-icons.size-sm a {
	line-height: 34px;
	height: 34px;
	width: 34px;
	font-size: 14px;
}

.social-icons a.facebook:hover {
	background-color: var(--silverLabel);
}

.social-icons a.instagram:hover {
	background-color: var(--silverLabel);
}

@media (max-width: 834px) {
	nav {
		margin-top: -1.5%;
	}
}

@media (max-width: 768px) {

	.parallax-container {
		display: block;
		margin-top: 5%;
	}

	/* Product content */
	.product-paragraph {
		display: block;
		padding: 5%;
	}

	/*  Media content  */
	.content {
		position: static;
		margin: auto;
		transform: none;
		font-size: 1.5em;
		padding-top: 30%;
	}

	.content h1 {
		font-size: 1.8em;
	}

	.social-icons li.title {
		display: block;
		margin-right: 0;
		font-weight: 600;
	}
}

@media (max-width: 480px) {


	nav {
		display: flex;
		width: 100%;
		align-items: flex-start;
		padding: 4%;
	}

	.content {
		margin: auto;

	}

	.content h1 {
		font-size: 1.8em;
	}

	.copyright-text {
		padding: 10%;
	}

}